import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

import { Container, NoDataContainer, PlayersContainer } from './styles';
import {
  InfoContainer, InfoIcon, Popup, ViewMore, ViewMoreArrow,
} from '../../../styles';
import { CategoryTitle } from '../../styles';

import Player from '../Player';
import { P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import ContentLoader from '../../../../../components/ContentLoader';

import { fetchRaisedHandsPlayers } from '../../../reducers';
import { H3 } from '../../../../../components';
import RaiseHand from '../../../../../components/Layout/components/Header/components/RaiseHand';

const WantToPlay = ({ masterSport }) => {
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state?.session);
  const {
    data,
    status,
    sportType,
  } = useSelector(state => state?.friendlyMatches?.raisedHands);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (
      (!isEmpty(accountInfo) && status === 'idle')
      || (status === 'succeeded' && data?.page && data?.page !== page)
      || (sportType && sportType !== masterSport && status === 'succeeded')
    ) {
      if (sportType !== masterSport && page > 1) {
        setPage(1);
      } else {
        dispatch(fetchRaisedHandsPlayers({
          page,
          limit: 6,
        }));
      }
    }
  }, [status, accountInfo, page, sportType, masterSport]);

  const loadMoreResults = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <Container smallerMarginTop>
      {data?.results && data.results.length > 0 && (
        <>
          <CategoryTitle large bold>
            <Trans ns="friendlyMatches" i18nKey="wantToPlay">Want to Play</Trans>
            <InfoContainer>
              <InfoIcon src={LoadImage('friendly-matches/info.svg')} alt="Find out more" />
              <Popup>
                <Trans ns="friendlyMatches" i18nKey="wantToPlayPopUp">
                  Players that are looking for a match in the immediate short term
                </Trans>
              </Popup>
            </InfoContainer>
          </CategoryTitle>

          <PlayersContainer>
            {data?.results?.map((result, key) => <Player key={key} data={result} />)}
          </PlayersContainer>

          {data.totalPages > page && (
            <ViewMore onClick={loadMoreResults}>
              <P xSmall bold>
                <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
              </P>
              <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View more results" />
            </ViewMore>
          )}

          {status === 'loading' && data?.page !== page && (
            <PlayersContainer className="mt30">
              <ContentLoader type="userCardList" items={6} />
            </PlayersContainer>
          )}
        </>
      )}

      {data && !data?.results?.length && status === 'succeeded' && (
        <NoDataContainer className="text-center">
          <img src={LoadImage('equipment-icon.svg')} alt="" width={42} height={45} />
          <H3><Trans ns="friendlyMatches" i18nKey="playFriendlyMatch">Play a Friendly Match</Trans></H3>
          <P className="mb30">
            <Trans ns="friendlyMatches" i18nKey="playFriendlyMatchSubtitle">
              Be the 1st one to show that you want to play a friendly match as soon as possible!
            </Trans>
          </P>
          <RaiseHand handRaisedData={accountInfo?.handRaised} />
        </NoDataContainer>
      )}

      {status === 'loading' && !data && (
        <PlayersContainer>
          <ContentLoader type="userCardList" items={6} />
        </PlayersContainer>
      )}
    </Container>
  );
};

export default WantToPlay;
