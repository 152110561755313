export default {
  RAISE_HAND_SUCCESS: 'Felkelted a kezed egy barátságos mérkőzésre.',
  LOWER_HAND_SUCCESS: 'Ти понижи ръката си.',
  createMatch: 'Нов мач',
  createNewMatchInvite: 'Покана за мача',
  createNewMatchInvite_tennis: 'Покана за мача',
  createNewMatchInvite_padel: 'Покана за мача',
  addPlayedMatch: 'Изигран мач',
  addPlayedMatch_tennis: 'Изигран мач',
  addPlayedMatch_padel: 'Изигран мач',
  raiseHand: {
    text: 'Вдигни ръка',
    title: 'Вдигнете ръка за приятелски мач!',
    title_tennis: 'Вдигнете ръка за приятелски мач!',
    title_padel: 'Вдигнете ръка за приятелски мач!',
    description: 'Покажи на другите играчи, че си готов да играеш веднага.',
    gameTypeLabel: 'Играйте приятелски мач на',
    within: 'в период',
    next3Days: 'Следващите 3 дни',
    next7Days: 'Следващите 7 дни',
    thisWeekend: 'Този уикенд',
    withPlayers: 'С Играчи',
    aboveMyLevel: 'Моето ниво +/-1',
    myLevel: 'Само моят ниво',
    lower: 'Спусни ръката си',
    daysLeft: '{{time}} дни',
    dayLeft: '{{time}} дни',
    singlesAndDoubles: 'Сингъл и Двойки',
    sameLevelDescription: 'Същото ниво',
    aboveLevelDescription: 'Ниво +/-1',
    tooltip: 'Той иска да изиграе приятелски мач възможно най-скоро',
  },
  startMatch: 'Старт',
  startMatchButton: 'Мачът започва',
  activeMatches: 'Мачове <1>({{ matches }})</1>',
  requests: 'Заявки <1>({{ requests }})</1>',
  scores: 'Резултати',
  friendlyMatches: 'Приятелски Мачове',
  friendlyMatches_tennis: 'Приятелски Мачове',
  friendlyMatches_padel: 'Приятелски Мачове',
  sportyaPartnerClubs: 'Партньорски Клубове Sportya',
  partnerClubsPopup: 'Партньорските клубове на Sportya са партньорски места, където можете да участвате в събития на Sportya или приятелски мачове.',
  inviteFriends: 'Поканете вашите приятели или други играчи на нов мач или добавете вече изигран мач към Sportya',
  wantToPlay: 'Искат да играят мач',
  wantToPlayPopUp: 'Играчи, които вдигнаха ръка за незабавен приятелски мач.',
  viewMore: 'Виж повече',
  nearby: 'В зоната',
  distanceAway: '{{ kmDistance }} км',
  invite: 'Покана',
  rematch: 'Играй отново',
  invitePlayerInArea: 'Поканете играч наблизо',
  invitePlayerInAreaPopUp: 'Играчи в радиус от 30 км около местоположението, зададено във вашия профил в Sportya.',
  otherTopPicks: 'Други препоръки',
  otherTopPicksPopUp: 'Играчи, които преди сте срещали в приятелски или състезателни мачове и/или ваши приятели в Sportya.',
  searchSpecificPlayer: 'Искаш ли да потърсиш определен играч?',
  findAPlayer: 'Намерете Играч',
  singlesMatch: 'Сингъл',
  doublesMatch: 'Двойки',
  singlesFriendlyMatch: 'Сингъл Приятелски',
  doublesFriendlyMatch: 'Двойки Приятелски',
  scoresData: {
    opponent: 'Противник',
    opponents: 'Противници',
  },
  ranked: 'Конкурентен',
  unranked: 'Обучение',
  matchType: 'Вид мач',
  matchFormat: 'Формат на мач',
  createdBy: 'Създаден от',
  players: 'Играчи',
  yourTeam: 'Твоят отбор',
  opponentTeam: 'Противниковият отбор',
  viewInActivity: 'Вижте в Дейност',
  reportMatch: 'Докладвай съвпадението',
  matchId: 'Мач ID: {{ matchId }}',
  singleSet: '1 Единичен Комплект',
  '2setsWithSuperTieBreak': '2 Сета + Супер Тайбрек',
  '2setsOutOf3': '2 От 3 Сета',
  later: 'Без отметка',
  playFriendlyMatch: 'Играй приятелски мач',
  playFriendlyMatchSubtitle: 'Покажи на другите играчи, че искаш да играеш приятелски мач възможно най-скоро!',
  noMatch: 'Липсва съвпадение',
  editMatch: 'Редактирайте съвпадението',
  cancelMatch: 'Отмяна на мача',
  confirmMatch: 'Потвърди мача',
  withdraw: 'Оттегли се',
  goToFriendlyMatches: 'Отиди На Приятелски Мачове',
  requestsArrow: 'Заявки',
  matches: 'Мачове',
  latestRequest: 'Последното покана',
  nextMatch: 'Следващият мач',
  yourMatch: 'Вашият мач',
  noRequestsOrMatches: 'Нямате нови покани или приятелски тенис мачове?',
  noRequestsOrMatchesDescription: 'Поканете вашите приятели или други играчи на нов мач или добавете вече изигран мач към Sportya',
  modalAcceptMatchTitle: 'Приемете поканата за мач',
  modalDeclineMatchTitle: 'Откажете поканата за мач',
  modalCancelMatchTitle: 'Откажете мача',
  modalNoMatchFromRequestsTitle: 'Мачът не беше игран',
  modalNoMatchFromMatchesTitle: 'Мачът не беше игран',
  modalAcceptMatchSubtitle: 'Приемате ли поканата за мач?',
  modalDeclineMatchSubtitle: 'Сигурен ли сте, че искате да откажете тази покана за мач?',
  modalCancelMatchSubtitle: 'Сигурен ли сте, че искате да отмените този мач?',
  modalNoMatchFromRequestsSubtitle: 'Сигурен ли сте, че искате да откажете участието си в този мач и да го маркирате като недиспутиран?',
  modalNoMatchFromMatchesSubtitle: 'Сигурен ли сте, че искате да откажете участието си в този мач и да го маркирате като недиспутиран?',
  modalWithdrawSubtitle: 'Сигурен ли си, че искаш да се оттеглиш от този мач?',
  accept: 'Приеми',
  acceptMatch: 'Приемете мача',
  decline: 'Откажи',
  cancel: 'Отказ',
  goBack: 'Назад',
  mark: 'Липсва съвпадение',
  addScore: 'Добавете резултата',
  confirmScore: 'Потвърдете резултата',
  changeScore: 'Променете резултата',
  abandoned: 'Изоставяне',
  addScoreInfo: 'Вашият резултат ще чака потвърждение от други участници за максимум 48 часа, след което ще бъде автоматично потвърден и ще бъдат генерирани точки в случай на състезателен мач.',
  confirmScoreInfo: 'Ако текущият резултат е неправилен, попълнете и потвърдете съвпадението с правилния резултат. Новите резултати ще изчакат потвърждението на останалите участници максимум 48 часа, преди да бъдат валидирани.',
  confirmMatchScoreInfo: 'Ако текущият резултат е неправилен, попълнете и потвърдете съвпадението с правилния резултат. Новите резултати ще изчакат потвърждението на останалите участници максимум 48 часа, преди да бъдат валидирани.',
  played: 'Изигран',
  seeLocation: 'Виж на картата',
  noActiveMatches: 'Нямате активни приятелски тенис мачове',
  noMatchesSubtitle: 'Нямате никакви приятелски мачове, насрочени в момента. Поканете приятелите си или други играчи и започнете нов мач сега!',
  noOpenRequests: 'Не сте получили покани',
  noRequestsSubtitle: 'Все още не сте получили покани за приятелски мачове. Поканете приятелите си или други играчи и играйте заедно!',
  noScores: 'Нямате валидирани резултати',
  noScoresSubtitle: 'Ти все още не си играл приятелски мач с потвърдени резултати. Увери се, че за всички до момента изиграни мачове са регистрирани и потвърдени резултатите или покани приятелите си или други играчи и заедно започнете нов мач!',
  reportReasons: {
    reason: 'Причината',
    selectTheReason: 'Изберете Причината',
    incorrectScore: 'Неправилен резултат',
    undisputedMatch: 'Неизигран мач',
    other: 'Друга причина',
    reportTextAreaInfo: 'Моля, предоставете допълнителна информация (по избор)',
  },
  reportedMatch: 'Вие сте докладвали за това съвпадение',
  reportedMatchDescription: 'Ще прегледаме доклада ти и ще се свържем с теб, ако е необходима допълнителна информация.',
  dateAndTime: 'Дата И Час',
  clubOrAddress: 'Клуб или Адрес',
  minute: 'Минути',
  hour: 'Час',
  format: 'Формат',
  decideLater: 'Ще реша по-късно',
  sendInvite: 'Изпратете поканата',
  captain: 'Капитан',
  partner: 'Партньор',
  pending: 'На изчакване',
  accepted: 'Потвърдено',
  declined: 'Отказа',
  score: 'Резултат',
  tie: 'Tie',
  matchDetails: 'Подробности за мача',
  selectMatchType: 'Изберете вида на тенис мача',
  proposedMatchFormat: 'Предложен формат на играта',
  countrySelect: 'Показани са местоположения от {{country}} <2>Смени държавата</2>',
  playerMinMaxLevelError: 'Нивото на противника не е съвместимо с изискванията за нивото на Компетитивен мач. Моля, изберете играч с ниво на игра в диапазона {{min}}-{{max}}, или променете типа мач в Тренировъчен мач, за да продължите да играете с вече избрания играч.',
  teamMinMaxLevelError: 'Средният ниво на отбора не е съвместим с изискванията за ниво за Компетитивен Мач. Моля, изберете отбор със средно ниво на игра в интервала {{min}}-{{max}} или променете типа на мача в Тренировъчен Мач, за да продължите да играете с вече избрания отбор.',
  updateMatch: 'Актуализирайте съвпадението',
  pendingScorePopup: 'Резултатът изчаква потвърждение - Моля, потвърдете или коригирайте резултата до {{ dueTime }}.',
  invitationDeclined: 'Отказано покана - Моля, прегледайте детайлите на мача и предприемете необходимите действия',
  playerWithdrawn: 'Оттеглил се играч - Моля, прегледайте детайлите на мача и предприемете необходимите действия',
  noMatchReported: 'Липсва съвпадение - Моля, прегледайте детайлите на мача и предприемете необходимите действия',
  inactiveScoreUpdate: 'Резултатът не може да бъде добавен / потвърден / променен в момента поради оттеглянето на играчи / непотвърдени играчи',
  decideGameFormatLater: 'Играчите могат да решат формата на мача по-късно или да го попълнят след мача.',
  hasTimeLimitError: 'Съжаляваме, но не можете да добавите състезателен мач, изигран преди повече от 5 дни. Системата позволява добавянето само на състезателни мачове, изиграни в рамките на 5 дни от датата на мача. Ако все пак искате да добавите този мач към историята на мачовете си, можете да го добавите като тренировъчен мач без точки.',
  confirmMatchScore: 'Потвърдете съвпадението и резултата',
  rankedFriendlyMatches: 'Приятелски Състезателни Мачове',
  generalRank: 'Общо Класиране',
  totalMatches: 'Всички Мачове',
  allActivity: 'Цялата Дейност',
  minutesUntil: 'краен срок {{time}} мин',
  hoursUntil: 'краен срок {{time}} ч',
  daysUntil: 'краен {{time}} дни',
  monthsUntil: 'краен {{time}} месеца',
  yearsUntil: 'краен {{time}} години',
  saveMatch: 'Запази Мача',
  gameType: 'Тип на играта',
  rankedMatch: 'Компетитивен мач',
  unrankedMatch: 'Тренировъчен мач',
  generatesPoints: 'Генерира точки в ранглистите. Формат на мача: 2 От 3 Сета.',
  doesntGeneratePoints: 'Не генерира точки в ранглистите. На разположение са различни формати на мачове.',
  matchInvitation: 'Покана за мач',
  playedMatch: 'Изигран мач',
  editParticipantWarning: 'Моля, имайте предвид, че смяната на играч в мача ще нулира предишния резултат и резултатът трябва да бъде въведен отново и потвърден за окончателно валидиране.',
  playedMatchForm: {
    title: 'Успешно добавихте вече играния мач с:',
    desc: 'Вашият мач чака потвърждение от други участници, за да потвърди крайния резултат. За да ускориш процеса на валидиране на крайния резултат, препоръчваме ти лично да се свържеш с другите участници и да ги насърчиш да потвърдят твоя мач и резултат.',
    button: 'Разбрах',
  },
  matchInviteForm: {
    title: 'Успешно изпратихте своята покана за мач до:',
    desc: 'За най-голямо участие препоръчваме да се свържеш лично с поканените играчи и да ги насърчиш да приемат поканата.',
    button: 'Разбрах',
  },
  timeRequiredForPlayedMatch: 'Изиграните състезателни мачове могат да се добавят до 5 дни след датата на мача.',
  invitationPendingConfirmation: 'Поканите за мач трябва да бъдат приети от поканените играчи.',
  modalWithdrawTitle: 'Оттегляне от мача',
  modalWithdraw: 'Сигурен ли си, че искаш да се оттеглиш от този мач?',
  guide: {
    quickGuide: 'Кратко Ръководство',
    title: 'Кратко ръководство за приятелски мачове',
    intro: 'Изживейте радостта от играта на любимия си спорт с Приятелски Срещи.<br> Играйте с приятели или други играчи в случайни тренировъчни мачове или състезателни мачове, единични или двойни, и печелете точки, за да се изкачите в класацията.',
    ranked: '<strong>Състезателните мачове генерират точки за победителите</strong> в класацията, когато крайните резултати са валидирани:<br><strong>+4 точки</strong> за победа в мач срещу същото ниво<br><strong>+2 точки</strong> за победа срещу по-ниско ниво<br><strong>+6 точки</strong> за спечелен мач срещу по-високо ниво',
    unranked: '<strong>Тренировъчни мачове</strong> са случайни мачове за забавление или тренировка.',
    scores: '<strong>Резултатите</strong> трябва да бъдат потвърдени от опоненти (или поне един опонент за двойки) в рамките на максимум 48 часа, за да бъдат валидирани. Всяка промяна в резултата рестартира таймера.',
    playedMatches: '<strong>Изиграните състезателни</strong> мачове могат да се добавят до 5 дни след датата на мача.',
    withdrawals: 'Всяко <strong>Оттегляне</strong> или докладване на <strong>Липсва съвпадение</strong> от участник в мача изисква вниманието на организатора на мача, за да прегледа подробностите за мача и да предприеме необходимите действия.<br> <strong>1 x Липсва съвпадение</strong> в мач на сингъл анулира мача и за двамата играчи<br><strong>2 x Липсва съвпадение</strong> от противниковия отбор в мач на двойки анулира мача и за двамата екипи',
    contact: '<strong>Свържете се лично с другите участници</strong> в мача, за да <strong>ги насърчите да приемат мача</strong> или да ги информирате предварително за вашето намерение да направите промени или да отмените мача. Това ще помогне да се избегне объркване или друго неудобство за другите играчи.',
  },
  report: 'Докладвай',
  gotIt: 'Разбрах',
  withdrawn: 'Оттегляне',
  canceled: 'Отменен',
  scoresChangeInfo: 'Можете да промените резултата за този мач само с помощта на опцията Промяна на резултата, след като запазите всички актуализации и затворите този формуляр.',
  rankedTooltip: {
    title: 'Победителите ще получат:',
    1: '+4 точки за победа в мач срещу същото ниво',
    2: '+2 точки за победа срещу по-ниско ниво',
    3: '+6 точки за спечелен мач срещу по-високо ниво',
  },
  rankBeforeMatch: 'Класиране преди мача:',
  inviteToMatch: 'Покана за мача',
  cancelPopup: {
    title: 'Сигурни ли сте, че искате да напуснете тази страница?',
    stay: 'Оставам на страницата',
    quit: 'Напускам страницата',
  },
  invitationMovedToMatches: 'Поканата е приета успешно! Съвпадението {{matchId}} е преместено в ',
  matchMovedToScores: 'Резултатът е потвърден успешно! Мачът {{matchId}} е преместен в ',
  playedMatchMovedToScores: 'Резултатът е потвърден успешно! Мачът {{matchId}} е преместен в ',
  playedMatchMovedToMatches: 'Изиграният мач е успешно потвърден! Съвпадението {{matchId}} е преместено в ',
  scoreValidationInvalidWinner: 'Моля, проверете внимателно резултата си и попълнете точния и пълен резултат, така че да може да се определи победител/печелив отбор на базата на него.',
  landingPage: {
    header: {
      title: 'Играй Перфектния Си Мач',
      description: `Участвате в неформални некласирани мачове или конкурентни класирани мачове, както на сингъл, така и на двойки, в тенис, падел и други спортове. Открийте вълнението от играта с приятели или други играчи в Sportya и изследвайте разнообразни стилове на игра.`,
    },
    section1: {
      title: 'Възродете Вашия Конкурентен Дух',
      description: `Освободете страстта си към тенис, падел и други спортове с ракета, като се свържете с аматьорски играчи във вашия район чрез Sportya. Независимо дали предизвиквате стари приятели или търсите нови съперници, изпитайте тръпката от конкурентни и тренировъчни мачове. Следете напредъка си и вижте кой ще излезе победител в края на всеки сезон.`,
    },
    section2: {
      title: 'Flex Play - Персонализирайте Играта Си, Както Желаете',
      description: `Насладете се на върховна гъвкавост с Приятелски Мачове, налични по всяко време и навсякъде. Поемете контрол, използвайки Sportya Chat за свързване с играчи, определяне на време и място, и се пригответе за деня на мача. Търсите предложения за места за игра? Проверете нашите партньорски клубове и резервирайте онлайн техните кортове и спортни съоръжения.`,
    },
    section3: {
      title: 'Подобрете Уменията Си, Прегърнете Нови Предизвикателства',
      description: `Всеки играч предлага уникално предизвикателство, предоставяйки ви възможност да се развивате и да станете по-добър атлет. Приемете различни стилове на игра и се възползвайте от възможностите за подобряване на играта си с всеки мач.`,
    },
    section4: {
      title: 'Повдигнете Ръка - Уведомете Други Играчите, Че Търсите Мач',
      description: `Използвайте опцията „Повдигнете ръка” в Sportya и уведомете други играчи в региона си, че търсите мач в близко бъдеще.`,
    },
    section5: {
      title: 'Мач Намерен? Резервирайте Корт / Спортно Съоръжение Чрез Sportya',
      description: `Открийте налични кортове и спортни съоръжения в района си и съберете приятелите си за незабравим мач. Sportya ще ускори вашето пътуване от намиране на мач до резервиране на корт / съоръжение с само няколко клика. Кажете сбогом на досадните телефонни обаждания и изгубеното време.`,
    },
    actions: {
      title: 'Изиграйте най-добрия си тенис мач!',
      button: 'Присъедини се сега',
    }
  },
};
