import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import MatchInvitationForm from './components/CreateMatch/MatchInvitation';
import MatchPlayedForm from './components/CreateMatch/PlayedMatch';

import {
  BackButton,
  FormikContainer,
  MatchFormContainer,
  // MatchFormOption,
  // MatchFormOptions,
  MatchFormTitle,
} from './styles';

import { get } from '../../../components/common/http';
import LoadImage from '../../../components/common/LoadImage';
import { Contain, P } from '../../../components/Collection';
import { formatMatchId } from '../helpers';
import ContentLoaderHandler from '../../../components/ContentLoader';

const MatchForm = ({
  create, edit, rematch, played, invite,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('friendlyMatches');
  const { masterSport } = useSelector(state => state.session);
  // const [selectedForm, setSelectedForm] = useState(played ? 'played' : 'invite');
  const selectedForm = played ? 'played' : 'invite';
  // const isFormSelected = type => type === selectedForm;
  const { match, fetch } = location.state || {};
  const [isLoading, setIsLoading] = useState(fetch);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const [player, setPlayer] = useState();
  const [opponent, setOpponent] = useState();

  const [playerPartner, setPlayerPartner] = useState();
  const [opponentPartner, setOpponentPartner] = useState();

  const handlePlayerSet = {
    player: setPlayer,
    opponent: setOpponent,
    playerPartner: setPlayerPartner,
    opponentPartner: setOpponentPartner,
  };

  useEffect(() => {
    const fetchData = async () => {
      const playerTypes = ['player', 'opponent', 'opponentPartner', 'playerPartner'];

      const promises = playerTypes
        .filter(type => match[type])
        .map(type => get(`/accounts/${match[type]?.id ?? ''}`)
          .then(({ data: { data } }) => handlePlayerSet[type](data))
          .catch(() => history.back('/friendly-matches')));

      await Promise.allSettled(promises);
    };

    if (fetch) {
      fetchData();
    }
  }, [fetch, match]);

  useEffect(() => {
    if (fetch) {
      const isDoubles = match?.gameType === 'doubles';
      if (isDoubles) {
        if ((match?.player ? player : true) && opponent && playerPartner && opponentPartner) {
          setIsLoading(false);
        }
      } else if (opponent) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [player, opponent, playerPartner, opponentPartner]);

  const formTitle = t((create && played)
    ? 'addPlayedMatch' : create || invite
      ? 'createNewMatchInvite' : edit ? 'editMatch' : 'rematch', { context: masterSport });

  // const formTypes = [
  //   { label: t('matchInvitation'), key: 'invite' },
  //   { label: t('playedMatch'), key: 'played' },
  // ];

  const formOptions = {
    invite: <MatchInvitationForm
      match={fetch ? {
        ...match,
        ...{
          player, opponent, playerPartner, opponentPartner,
        },
      } : match}
      rematch={rematch}
      edit={edit}
      invite={invite}
      cancelModalState={{
        handler: setIsCancelModalVisible,
        visible: isCancelModalVisible,
      }}
    />,
    played: <MatchPlayedForm
      match={match}
      edit={edit}
      cancelModalState={{
        handler: setIsCancelModalVisible,
        visible: isCancelModalVisible,
      }}
    />,
  };

  useEffect(() => {
    if ((edit || rematch) && !match) {
      history.push('/friendly-matches/create');
    }
  }, []);

  return !isLoading ? (
    <MatchFormContainer>
      <MatchFormTitle addMarginBottom={!edit}>
        {formTitle}
      </MatchFormTitle>
      {edit && (
        <P textAlign="center" margin="0 0 30px 0">
          <Trans ns="friendlyMatches" i18nKey="matchId">
            {{ matchId: formatMatchId(match.matchId) }}
          </Trans>
        </P>
      )}
      <FormikContainer>
        <BackButton
          src={LoadImage('friendly-matches/match-form/back-arrow.svg')}
          onClick={() => setIsCancelModalVisible(!isCancelModalVisible)}
        />
        {/* {create && !invite && (
          <MatchFormOptions>
            {formTypes?.map((form, key) => (
              <MatchFormOption
                key={key}
                onClick={() => setSelectedForm(form.key)}
                isSelected={isFormSelected(form.key)}
              >
                {form.label}
              </MatchFormOption>
            ))}
          </MatchFormOptions>
        )}  */}
        {formOptions[selectedForm]}
      </FormikContainer>
    </MatchFormContainer>
  ) : (
    <Contain
      align="center"
      justify="center"
      width="100%"
      margin="150px 0 150px 0"
    >
      <ContentLoaderHandler />
    </Contain>
  );
};

export default MatchForm;
