export default {
  RAISE_HAND_SUCCESS: 'Barátságos mérkőzésre emelte a kezét.',
  LOWER_HAND_SUCCESS: 'A leengedted a kezed.',
  createMatch: 'Új teniszmeccs',
  createNewMatchInvite: 'Meghívó a mérkőzésre',
  createNewMatchInvite_tennis: 'Meghívó a mérkőzésre',
  createNewMatchInvite_padel: 'Meghívó a mérkőzésre',
  addPlayedMatch: 'Lejátszott mérkőzés',
  addPlayedMatch_tennis: 'Lejátszott mérkőzés',
  addPlayedMatch_padel: 'Lejátszott mérkőzés',
  raiseHand: {
    text: 'Emeld fel a kezed',
    title: 'Emeld fel a kezed egy barátságos mérkőzésre!',
    title_tennis: 'Emeld fel a kezed egy barátságos mérkőzésre!',
    title_padel: 'Emeld fel a kezed egy barátságos mérkőzésre!',
    description: 'Mutasd meg a többi játékosnak, hogy készen állsz a lehető leghamarabb játszani.',
    gameTypeLabel: 'Játssz egy barátságos mérkőzést',
    within: 'időszakban',
    next3Days: 'Következő 3 nap',
    next7Days: 'Következő 7 nap',
    thisWeekend: 'Ezen a hétvégén',
    withPlayers: 'Játékosokkal',
    aboveMyLevel: 'Az én szintem +/-1',
    myLevel: 'Csak az én szintem',
    lower: 'Engedd le a kezed',
    daysLeft: '{{time}} nap',
    dayLeft: '{{time}} nap',
    singlesAndDoubles: 'Egyén & Páros',
    sameLevelDescription: 'Ugyanaz a szint',
    aboveLevelDescription: 'Szint +/-1',
    tooltip: 'A lehető leghamarabb szeretne egy barátságos mérkőzést játszani',
  },
  startMatch: 'Rajt',
  startMatchButton: 'Indítsa el a mérkőzést',
  activeMatches: 'Mérkőzések <1>({{ matches }})</1>',
  requests: 'Meghívók <1>({{ requests }})</1>',
  scores: 'Pontszámok',
  friendlyMatches: 'Barátságos Mérkőzések',
  friendlyMatches_tennis: 'Barátságos Mérkőzések',
  friendlyMatches_padel: 'Barátságos Mérkőzések',
  sportyaPartnerClubs: 'Sportya Partnerklubok',
  partnerClubsPopup: 'A Sportya Partner Clubok olyan partnerhelyek, ahol részt vehetsz a Sportya eseményeken vagy barátságos mérkőzéseken.',
  inviteFriends: 'Hívd meg barátaidat vagy más játékosaidat egy új meccsre, vagy adj hozzá egy már lejátszott meccset a Sportya',
  wantToPlay: 'Szeretnének játszani egy meccset',
  wantToPlayPopUp: 'Játékosok, akik felemelték a kezüket egy azonnali barátságos mérkőzésre.',
  viewMore: 'Mutass többet',
  nearby: 'A területen',
  distanceAway: '{{ kmDistance }} km',
  invite: 'Meghívás',
  rematch: 'Játszd újra',
  invitePlayerInArea: 'Hívj meg egy közeli játékost',
  invitePlayerInAreaPopUp: 'Játékosok a 30 km-es közelségi sugárban, a Sportya profilodban beállított helyszín körül.',
  otherTopPicks: 'Egyéb ajánlások',
  otherTopPicksPopUp: 'Játékosok, akikkel korábban találkoztál barátságos vagy versenymérkőzéseken, valamint barátaid a Sportya platformon.',
  searchSpecificPlayer: 'Keresel egy adott játékost?',
  findAPlayer: 'Játékos keresése',
  singlesMatch: 'Egyéni Meccs',
  doublesMatch: 'Páros Meccs',
  singlesFriendlyMatch: 'Barátságos Egyéni Meccs',
  doublesFriendlyMatch: 'Barátságos Páros Meccs',
  scoresData: {
    opponent: 'Ellenfél',
    opponents: 'Ellenfelek',
  },
  ranked: 'Kompetitív',
  unranked: 'Barátságos',
  matchType: 'Mérkőzés Típusa',
  matchFormat: 'Mérkőzés Formátum',
  createdBy: 'Készítette',
  players: 'Játékosok',
  yourTeam: 'Csapatod',
  opponentTeam: 'Ellenfél csapata',
  viewInActivity: 'Tevékenység',
  reportMatch: 'Jelentsd a meccset',
  matchId: 'Meccs ID: {{ matchId }}',
  singleSet: 'Egy Szett',
  '2setsWithSuperTieBreak': '2 Szett + Szuper Tiebreak',
  '2setsOutOf3': '3-ból 2 Szett',
  later: 'Nincs kijelölés',
  playFriendlyMatch: 'Játsz egy barátságos meccse',
  playFriendlyMatchSubtitle: 'Mutasd meg a többi játékosnak, hogy szeretnél mielőbb barátságos mérkőzést játszani!',
  noMatch: 'Hiányzó egyezés',
  editMatch: 'Szerkessze a mérkőzést',
  cancelMatch: 'Mérkőzés törlése',
  confirmMatch: 'Mérkőzés megerősítése',
  withdraw: 'Visszalépés',
  goToFriendlyMatches: 'Menj a Barátságos Meccsekhez',
  requestsArrow: 'Meghívók',
  matches: 'Mérkőzések',
  latestRequest: 'A legutóbbi meghívás',
  nextMatch: 'A következő meccs',
  yourMatch: 'A te meccsed',
  noRequestsOrMatches: 'Nincsenek új meghívói vagy barátságos teniszmérkőzései?',
  noRequestsOrMatchesDescription: 'Hívd meg barátaidat vagy más játékosaidat egy új meccsre, vagy adj hozzá egy már lejátszott meccset a Sportya',
  modalAcceptMatchTitle: 'Fogadja el a mérkőzés meghívást',
  modalDeclineMatchTitle: 'Elutasítja a mérkőzés meghívást',
  modalCancelMatchTitle: 'Törölje a mérkőzés',
  modalNoMatchFromRequestsTitle: 'A mérkőzést nem játszották le',
  modalNoMatchFromMatchesTitle: 'A mérkőzést nem játszották le',
  modalAcceptMatchSubtitle: 'Elfogadja a mérkőzés meghívást?',
  modalDeclineMatchSubtitle: 'Biztosan el akarod utasítani ezt a mérkőzési meghívást?',
  modalCancelMatchSubtitle: 'Biztosan törli ezt a mérkőzést?',
  modalNoMatchFromRequestsSubtitle: 'Biztos benne, hogy vissza akarja utasítani ezt a részvételt, és elmulasztott meccsként jelöli meg a mérkőzést?',
  modalNoMatchFromMatchesSubtitle: 'Biztos benne, hogy vissza akarja utasítani ezt a részvételt, és elmulasztott meccsként jelöli meg a mérkőzést?',
  modalWithdrawSubtitle: 'Biztosan szeretnél visszavonulni ettől a mérkőzéstől?',
  accept: 'Elfogad',
  acceptMatch: 'Fogadd el a meccset',
  decline: 'Elutasítás',
  cancel: 'Visszavonás',
  goBack: 'Menjen vissza',
  mark: 'Hiányzó egyezés',
  addScore: 'Eredmény hozzáadása',
  confirmScore: 'Eredmény megerősítése',
  changeScore: 'Pontszám módosítása',
  abandoned: 'Elhagyott',
  addScoreInfo: 'Eredménye legfeljebb 48 órán keresztül más résztvevők általi megerősítésre vár, ezután automatikusan érvényesítésre kerül, és versenymérkőzés esetén pontokat generálnak.',
  changeScoreInfo: 'Ha az aktuális eredmény hibás, töltse ki és erősítse meg a helyes eredményt. Az új eredmények akár 48 órát is várnak a többi résztvevő megerősítésére, mielőtt érvényesítik őket.',
  confirmScoreInfo: 'Ha az aktuális eredmény hibás, töltse ki és erősítse meg a helyes eredményt. Az új eredmények akár 48 órát is várnak a többi résztvevő megerősítésére, mielőtt érvényesítik őket.',
  confirmMatchScoreInfo: 'Ha az aktuális eredmény hibás, töltse ki és erősítse meg a helyes eredményt. Az új eredmények akár 48 órát is várnak a többi résztvevő megerősítésére, mielőtt érvényesítik őket.',
  played: 'Játszott',
  seeLocation: 'Lásd a térképen',
  noActiveMatches: 'Nincsenek aktív baráti meccseid',
  noMatchesSubtitle: 'Nincs barátságos mérkőzésed jelenleg ütemezve. Hívd meg barátaidat vagy más játékosokat, és kezdj el egy új meccset most!',
  noOpenRequests: 'Nincsenek meghívók',
  noRequestsSubtitle: 'Te még nem kaptál meghívókat barátságos mérkőzésekre. Hívj meg barátaidat vagy más játékosokat, és játszatok együtt egy mérkőzést!',
  noScores: 'Nincsenek érvényesített pontszámai',
  noScoresSubtitle: 'Még nem játszottál barátságos mérkőzést érvényes pontszámmal. Bizonyosodj meg róla, hogy minden eddigi meccs pontszámai rögzítve és érvényesítve lettek, vagy hívj meg barátaidat vagy más játékosokat és játsszatok együtt egy új mérkőzést!',
  reportReasons: {
    reason: 'A bejelentés oka',
    selectTheReason: 'Válassza ki az okot',
    incorrectScore: 'Helytelen pontszám',
    undisputedMatch: 'A mérkőzést nem játszották le',
    other: 'Másik ok',
    reportTextAreaInfo: 'Kérjük, adjon meg további információkat (nem kötelező)',
  },
  reportedMatch: 'Ön jelentette ezt a mérkőzést',
  reportedMatchDescription: 'Átnézzük a jelentésedet, és ha szükséges, visszajelzünk Neked bármilyen további információval kapcsolatban.',
  dateAndTime: 'Dátum & Idő',
  clubOrAddress: 'Klub vagy Cím',
  minute: 'Percek',
  hour: 'Óra',
  format: 'Formátum',
  decideLater: 'Dönts később',
  sendInvite: 'Küldje el a meghívót',
  captain: 'Kapitány',
  partner: 'Partner',
  pending: 'Várakozás',
  accepted: 'Megerősített',
  declined: 'Elutasította',
  score: 'Pontszám',
  tie: 'Tie',
  matchDetails: 'A mérkőzés részletei',
  selectMatchType: 'Válassza ki a teniszmérkőzés típusát',
  proposedMatchFormat: 'A mérkőzés javasolt formátuma',
  countrySelect: '{{country}} származó helyek jelennek meg <2>Változtasd meg az országot</2>',
  playerMinMaxLevelError: 'Az ellenfeled szintje nem kompatibil a versenymérkőzés szintkövetelményével. Kérjük, válassz egy játékost a szinttartományon belül: {{min}}-{{max}}, vagy válts a Gyakorló mérkőzésre, hogy folytathasd a játékot a kiválasztott játékossal.',
  teamMinMaxLevelError: 'A csapat átlagos játékszintje nem egyeztethető össze a Competitive Match mérkőzés szintkövetelményével. Kérjük, válasszon egy olyan csapatot, amelynek átlagos szintje {{min}}-{{max}} között van, vagy váltson edzőmérkőzésre, hogy a már kiválasztott csapattal folytathassa a játékot.',
  updateMatch: 'Frissítse a mérkőzést',
  pendingScorePopup: 'Az eredmény várja a megerősítést - Kérjük, erősítsd meg az eredményt, vagy javítsd ki azt {{ dueTime }}-ig',
  invitationDeclined: 'Meghívás elutasítva - Kérjük, ellenőrizze a mérkőzés részleteit, és hozza meg a szükséges intézkedéseket',
  playerWithdrawn: 'Visszavont játékos - Kérjük, ellenőrizze a mérkőzés részleteit, és hozza meg a szükséges intézkedéseket',
  noMatchReported: 'Hiányzó egyezés - Kérjük, ellenőrizze a mérkőzés részleteit, és hozza meg a szükséges intézkedéseket',
  inactiveScoreUpdate: 'Az eredmény jelenleg nem adható hozzá / nem erősíthető meg / nem változtatható meg a visszavonuló vagy megerősítetlen játékosok miatt',
  decideGameFormatLater: 'A játékosok később dönthetnek a mérkőzés formátumáról, vagy a meccs után tölthetik ki.',
  hasTimeLimitError: 'Sajnáljuk, de nem adhat hozzá 5 napnál régebben lejátszott versenymérkőzéseket. Csak a mérkőzés dátumától számított 5 napon belül hozzáadott versenymérkőzéseket fogadjuk el. Ha továbbra is szeretné felvenni ezt a mérkőzést a meccselőzményeibe, felveheti ezt a meccset gyakorlómeccsként pontok nélkül.',
  confirmMatchScore: 'Erősítse meg a mérkőzést és az eredményt',
  rankedFriendlyMatches: 'Barátságos Versenymérkőzések',
  generalRank: 'Általános Rangsor',
  totalMatches: 'Összes Mérkőzés',
  allActivity: 'Minden Tevékenység',
  minutesUntil: 'határidő {{time}} min',
  hoursUntil: 'határidő {{time}} h',
  daysUntil: 'határidő {{time}} nap',
  monthsUntil: 'határidő {{time}} hónap',
  yearsUntil: 'határidő {{time}} év',
  saveMatch: 'Mentés Mérkőzés',
  gameType: 'Játék típusa',
  rankedMatch: 'Versenyszerű meccs',
  unrankedMatch: 'Edzőmeccs',
  generatesPoints: 'Ranglistapontokat generál. Mérkőzés formátum: 3-ból 2 Szett.',
  doesntGeneratePoints: 'Nem generál ranglistapontokat. Különböző mérkőzésformátumok állnak rendelkezésre.',
  matchInvitation: 'Mérkőzés meghívó',
  playedMatch: 'Lejátszott mérkőzés',
  editParticipantWarning: 'Kérjük, vegye figyelembe, hogy bármely játékos lecserélése a mérkőzésen visszaállítja a korábbi pontszámot, és a pontszámot újra be kell írni és meg kell erősíteni a végső érvényesítéshez.',
  playedMatchForm: {
    title: 'Sikeresen hozzáadta a már lejátszott mérkőzését a következővel:',
    desc: 'A mérkőzésed a többi résztvevő megerősítésére vár a végeredmény érvényesítéséhez. A végleges eredmény validálásának felgyorsítása érdekében javasoljuk, hogy személyesen vegye fel a kapcsolatot a többi résztvevővel és bátorítsa őket a meccs és az eredmény megerősítésére.',
    button: 'Értettem',
  },
  matchInviteForm: {
    title: 'Sikeresen elküldte a mérkőzés meghívóját a következő címre:',
    desc: 'A lehető legmagasabb részvétel érdekében javasoljuk, hogy személyesen vegye fel a kapcsolatot a meghívott játékosokkal, és bátorítsa őket a meghívás elfogadására.',
    button: 'Értettem',
  },
  invitationPendingConfirmation: 'A mérkőzésre szóló meghívókat a meghívott játékosoknak el kell fogadniuk.',
  timeRequiredForPlayedMatch: 'A lejátszott versenymérkőzések a mérkőzés időpontjától számított legfeljebb 5 napon belül hozzáadhatók.',
  modalWithdrawTitle: 'Kilépés a mérkőzésről',
  modalWithdraw: 'Biztosan szeretnél visszavonulni ettől a mérkőzéstől?',
  guide: {
    quickGuide: 'Gyors Útmutató',
    title: 'Gyors útmutató a barátságos mérkőzésekhez',
    intro: 'Tapasztalja meg a teniszezés örömét a Sportya barátságos mérkőzéseken.<br> Játsszon barátokkal vagy más játékosokkal hétköznapi edzőmérkőzéseken vagy versenymérkőzéseken, egyesben vagy párosban, és szerezzen pontokat, hogy feljusson a ranglistára.',
    ranked: '<strong>A versenymérkőzéseken a győztesek pontokat szereznek</strong> a ranglistán, ha a végeredmény érvényes:<br><strong>+4 pont</strong> az azonos szint elleni meccs megnyeréséért<br><strong>+2 pont</strong> az alacsonyabb szint elleni meccs megnyeréséért<br><strong>+6 pont</strong> a magasabb szint elleni meccs megnyeréséért',
    unranked: '<strong>A Gyakorló mérkőzések</strong> hétköznapi mérkőzések szórakozás vagy gyakorlás céljából.',
    scores: 'A <strong>Pontszámokat</strong> az ellenfélnek (vagy páros esetén legalább egy ellenfélnek) meg kell erősítenie legfeljebb 48 órán belül az érvényesítéshez. Bármilyen változtatás a pontszámban, visszaállítja az időzítőt.',
    playedMatches: '<strong>A lejátszott versenymérkőzé</strong>sek a mérkőzés időpontjától számított legfeljebb 5 napon belül hozzáadhatók.',
    withdrawals: 'Bármilyen <strong>Visszavonás</strong> vagy a <strong>Hiányzó egyezés</strong> bejelentése megköveteli a mérkőzés szervezőjének figyelmét, hogy áttekintse a mérkőzés részleteit, és megtegye a szükséges lépéseket.<br><strong>1 x Hiányzó egyezés</strong> törli a mérkőzést mindkét játékos számára az egyesmérkőzésen<br><strong>2 x Hiányzó egyezés</strong> az ellenfél csapatától, a páros mérkőzésen mindkét csapat mérkőzését törli',
    contact: '<strong>Személyesen vegye fel a kapcsolatot a mérkőzés többi résztvevőjével</strong>, hogy <strong>elfogadja a mérkőzést</strong>, vagy előre tájékoztassa őket a mérkőzés módosítására vagy törlésére vonatkozó szándékáról. Ez segít elkerülni, hogy problémákat vagy kellemetlenségeket okozzon más játékosoknak.',
  },
  report: 'Jelentés',
  gotIt: 'Értettem',
  withdrawn: 'Visszavonás',
  canceled: 'Törölve',
  scoresChangeInfo: 'Csak a frissítések mentése és az űrlap bezárása után módosíthatja a találati pontszámot a Pontszám módosítása opcióval.',
  rankedTooltip: {
    title: 'A nyertesek a következőket kapják:',
    1: '+4 pont az azonos szint elleni meccs megnyeréséért',
    2: '+2 pont az alacsonyabb szint elleni meccs megnyeréséért',
    3: '+6 pont a magasabb szint elleni meccs megnyeréséért',
  },
  rankBeforeMatch: 'Helyezés a mérkőzés előtt:',
  inviteToMatch: 'Meghívó a mérkőzésre',
  cancelPopup: {
    title: 'Biztos, hogy elhagyja ezt az oldalt?',
    stay: 'Maradok az oldalon',
    quit: 'Elhagyom az oldalt',
  },
  invitationMovedToMatches: 'Meghívás sikeresen elfogadva! A(z) {{matchId}} mérkőzés átkerült a ',
  matchMovedToScores: 'A pontszám sikeresen érvényesítve! A(z) {{matchId}} mérkőzés átkerült a ',
  playedMatchMovedToScores: 'A pontszám sikeresen érvényesítve! A(z) {{matchId}} mérkőzés átkerült a ',
  playedMatchMovedToMatches: 'Megerősítették a mérkőzést! A(z) {{matchId}} mérkőzés átkerült a ',
  scoreValidationInvalidWinner: 'Kérjük, ellenőrizze gondosan az Ön pontszámát, majd töltse ki a helyes és teljes pontszámot, hogy az alapján kijelölhessük a győztest/nyertes csapatot.',
  landingPage: {
    header: {
      title: 'Játssz a tökéletes meccset',
      description: `Tapasztalja meg a barátaival vagy más játékosokkal való teniszezés örömét az edzőmérkőzéseken vagy a versenyképes egyes- vagy párosmérkőzéseken, miközben pontokat gyűjt, hogy feljusson a ranglistára.`,
    },
    section1: {
      title: 'Aktiválja újra versenyszellemét',
      description: `Aktiválja a tenisz iránti szenvedélyét, ha kapcsolatba lép más amatőr játékosokkal a környéken a Sportyán keresztül. Akár régi barátokat hív ki, akár új riválisokat keres, tapasztalja meg a verseny- és gyakorlómérkőzések izgalmát. Kövesse nyomon a fejlődést, és nézze meg, ki nyeri az egyes szezonokat.`,
    },
    section2: {
      title: 'Játsszon rugalmasan – Szabja testre játékát a saját stílusában',
      description: `Élvezze a maximális rugalmasságot a Sportya barátságos mérkőzésekkel, amelyek bármikor és bárhol elérhetők. Vedd meg a kezdeményezést a Chat használatával, hogy kapcsolatba léphess a játékosokkal, beállítsd a mérkőzés idejét és helyét, és készülj fel a játékra. Javaslatokra van szüksége a játék helyszíneivel kapcsolatban? A Sportya klub profiloldalain további információkat találhat a partnerklubokról, így a leginkább ihletett választást hozhatja.`,
    },
    section3: {
      title: 'Fejleszd a játékodat, fogadj el új kihívásokat',
      description: `Minden játékos egyedi kihívás elé állít, lehetőséget adva arra, hogy fejlődj, és jobb teniszezővé válj. Vegyen fel különféle játékstílusokat, és használja ki az esélyt, hogy minden mérkőzésen javítsa játékát.`,
    },
    section4: {
      title: 'Emelje fel a kezét egy barátságos mérkőzésre',
      description: `Mutasd meg a régiód többi játékosának, hogy készen állsz a játékra. Használja a Emelje fel a kezét funkciót, hogy kifejezze az elkövetkező napok mérkőzésére való rendelkezésre állását, izgalmas várakozást teremtve a következő Sportya-mérkőzésre.`,
    },
    section5: {
      title: 'Megtaláltad a meccset? Foglalj pályát a Sportya-n keresztül',
      subtitle: 'Megjelenés 2023 negyedik negyedévében',
      description: `Fedezd fel a környéked elérhető pályáit, és gyűjtsd össze a barátaidat egy felejthetetlen meccsre. A Sportya néhány kattintással felgyorsítja az utadat a meccskereséstől a pályafoglalásig. Mondj búcsút a fárasztó telefonhívásoknak és az elvesztegetett időnek.`,
    },
    actions: {
      title: 'Hozd össze a legjobb meccsed!',
      button: 'Csatlakozz most',
    }
  },
};
